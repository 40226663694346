import Home from "@mui/icons-material/Home";

import config from '../config';
import NavigationLink from '../interfaces/NavigationLink';
import Permissions from '../shared/enums/Permissions';
import CampaignDelight from '../shared/icons/CampaignDelight';
import CustomerCube from '../shared/icons/CustomerCube';
import ImportExport from '../shared/icons/ImportExport';
import Intelliclose from '../shared/icons/Intelliclose';
import Offerio from '../shared/icons/Offerio';
import Reports from '../shared/icons/Reports';
import Retensee from '../shared/icons/Retensee';
import SocialMediaReward from '../shared/icons/SocialMediaReward';
import { Settings } from '@mui/icons-material';
import CommerceConnect from '../shared/icons/CommerceConnect';
import PollIcon from '@mui/icons-material/Poll';
import FlipSell from "../shared/icons/FlipSell";
import JourneyBuilder from '../shared/icons/JourneyBuilder';
import SocialMedia from '../shared/icons/SocialMedia';

const { dashboardClassic } = config;

const navConfig: NavigationLink[] = [
  {
    requiredPermissions: [Permissions.OVERVIEW],
    label: 'Home',
    content: `/`,
    externalLink: false,
    icon: Home,
  },
  {
    requiredPermissions: [],
    label: "Customer Cube",
    icon: CustomerCube,
    isNew: true,
    content: [
      {
        content: `/customer-cube/customer-analysis`,
        label: "Customer Analysis",
        requiredPermissions: [Permissions.CUSTOMER_ANALYSIS],
        externalLink: false,
      },
      {
        content: `/customer-cube/store-analysis`,
        label: "Store Analysis",
        requiredPermissions: [Permissions.STORE_ANALYSIS],
        externalLink: false,
      },
      {
        content: `/customer-cube/store-sales-forecast`,
        label: "Store Sales Prediction",
        requiredPermissions: [Permissions.STORE_ANALYSIS, Permissions.STORE_SALES_PREDICTION],
        externalLink: false,
      },
      {
        content: `/customer-cube/festival-analysis`,
        label: 'Festival Analysis',
        requiredPermissions: [Permissions.FESTIVAL_ANALYSIS],
        externalLink: false,
      },
      {
        label: 'Product',
        requiredPermissions: [],
        externalLink: false,
        content: [
          {
            content: `/customer-cube/product-analysis`,
            label: "Product Analysis",
            requiredPermissions: [Permissions.PRODUCT_ANALYSIS],
            externalLink: false,
          },
          {
            content: `/customer-cube/product-frequency`,
            label: "Product Frequency",
            requiredPermissions: [Permissions.PRODUCT_FREQUENCY],
            externalLink: false,
          },
          {
            content: `${dashboardClassic}/basket-analysis`,
            label: "Basket Analysis",
            requiredPermissions: [Permissions.BASKET_ANALYSIS],
            externalLink: true,
          },
        ],
      },
      {
        content: `/customer-cube/customer-360`,
        label: "Customer 360",
        requiredPermissions: [Permissions.CUSTOMER_360],
        externalLink: false,
      },
      {
        content: `/migrate-customers`,
        label: "Migrate Customer",
        requiredPermissions: [Permissions.MIGRATE_CUSTOMER],
        externalLink: false,
      },
      {
        content: `/omni-channel-dashboard`,
        label: "Digital Dashboard",
        requiredPermissions: [],
        externalLink: false,
      },
    ],
    externalLink: false,
  },
  {
    requiredPermissions: [Permissions.LOYALTY],
    label: "RetenSee",
    content: [
      {
        requiredPermissions: [Permissions.LOYALTY],
        label: "Adhoc Points",
        content: `/loyalty-adhoc-points`,
        externalLink: false,
      },
    ],
    externalLink: true,
    icon: Retensee,
  },
  {
    requiredPermissions: [Permissions.SURVEY],
    label: 'Survey',
    content: '/survey/list',
    externalLink: false,
    isNew: true,
    icon: PollIcon,
  },
  {
    requiredPermissions: [Permissions.VIEW_FLIPBOOK],
    label: 'FlipSell',
    content: '/flipsell',
    externalLink: false,
    isNew: true,
    icon: FlipSell,
  },
  {
    requiredPermissions: [Permissions.SOCIAL_MEDIA_REWARDS],
    label: "Social Media Rewards",
    icon: SocialMediaReward,
    content: [
      {
        content: `${dashboardClassic}/social-media-engagements`,
        label: "Rewards Summary",
        requiredPermissions: [Permissions.SOCIAL_MEDIA_REWARDS],
        externalLink: true,
      },
    ],
    externalLink: false,
  },
  {
    requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
    label: "Offerio",
    icon: Offerio,
    content: [
      {
        content: `offers/create`,
        label: "Create Offer",
        requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
        externalLink: false,
      },
      {
        content: `offers`,
        label: "Offers List",
        requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
        externalLink: false,
      },
      {
        content: `${dashboardClassic}/offers/search`,
        label: "Customer Offers Search",
        requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
        externalLink: true,
      },
      {
        content: `${dashboardClassic}/offers-analytics`,
        label: "Offers Analytics",
        requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
        externalLink: true,
      },
    ],
    externalLink: false,
  },
  {
    requiredPermissions: [Permissions.LEAD_CREATE],
    label: "Intelliclose",
    icon: Intelliclose,
    content: [
      {
        requiredPermissions: [Permissions.LEAD_CREATE],
        content: `/leads/create`,
        label: 'Create Lead',
        externalLink: false,
      },
      {
        content: `/leads`,
        label: 'Manage Leads',
        requiredPermissions: [Permissions.LEAD_MANAGE],
        externalLink: false,
      },
      {
        requiredPermissions: [Permissions.LEAD_DASHBOARD],
        content: `/lead-dashboard`,
        label: 'Lead Dashboard',
        externalLink: false,
      },
      {
        requiredPermissions: [Permissions.LEAD_MANAGE],
        content: `/leads/telecall-history`,
        label: 'Telecall History',
        externalLink: false,
      },
    ],
    externalLink: false,
  },
  {
    label: 'Journey Builder',
    icon: JourneyBuilder,
    requiredPermissions: [Permissions.JOURNEY_BUILDER_MANAGE],
    content: `/journeys`,
    externalLink: false,
    isNew: true
  },
  {
    label: 'Campaign Delight',
    icon: CampaignDelight,
    requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
    content: [
      {
        label: "Create Cohort",
        content: `${dashboardClassic}/create-cohorts`,
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
      {
        label: "List Cohorts",
        content: `${dashboardClassic}/manage-cohorts`,
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
      {
        label: "Create Campaign",
        content: `/campaign/create`,
        requiredPermissions: ["campaign_manage"],
        externalLink: false,
      },
      {
        label: "Manage Campaigns",
        content: `/campaign/list`,
        requiredPermissions: ["campaign_manage"],
        externalLink: false,
      },
      {
        label: "List Campaigns",
        content: `${dashboardClassic}/campaign/list`,
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
      {
        label: "Campaign Analytics",
        content: `${dashboardClassic}/campaign-analytics`,
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
      {
        label: 'Copy Write Templates',
        content: `/campaign/copy-write/templates`,
        requiredPermissions: [Permissions.COPY_WRITE],
        externalLink: false,
      },
      {
        label: "Email Templates",
        content: [
          {
            label: "Create",
            content: `${dashboardClassic}/email-templates/create`,
            requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
            externalLink: true,
          },
          {
            label: "List",
            content: `${dashboardClassic}/email-templates/list`,
            requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
            externalLink: true,
          },
        ],
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
      {
        label: " Templates",
        content: "/campaign/templates/sms",
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: false,
      },
    ],
    externalLink: false,
  },
  {
    label: "Commerce Connect",
    icon: CommerceConnect,
    requiredPermissions: [Permissions.COMMERCE_CONNECT],
    content: [
      {
        requiredPermissions: [Permissions.COMMERCE_CONNECT],
        content: `/commerce-connect/product-attributes`,
        label: 'Product Attributes',
        externalLink: false,
      },
      {
        requiredPermissions: [Permissions.COMMERCE_CONNECT],
        content: `/commerce-connect/upload-products`,
        label: 'Upload Products',
        externalLink: false,
      }
    ],
    externalLink: false,
  },
  {
    label: "Reports",
    icon: Reports,
    requiredPermissions: [],
    content: [
      {
        label: "Loyalty",
        content: [
          // {
          //   label: 'Tier Downgrade',
          //   content: `${dashboardClassic}/reports/tier-downgrade-report`,
          //   requiredPermissions: [Permissions.TIER_DOWNGRADE_REPORT],
          //   externalLink: true,
          // },
          {
            label: "Liability",
            content: `reports/loyalty-liability`,
            requiredPermissions: [Permissions.LOYALTY_LIABILITY_REPORT],
            externalLink: false,
          },
        ],
        requiredPermissions: [],
        externalLink: true,
      },
      {
        label: "Member",
        content: [
          {
            label: "Transaction",
            content: `reports/transaction`,
            requiredPermissions: [Permissions.TRANSACTION_REPORT],
            externalLink: false,
          },
          {
            label: "Enrollment",
            content: `reports/enrollment`,
            requiredPermissions: [Permissions.MEMBER_BILL_REPORT],
            externalLink: false,
          },
          {
            label: "Fraud",
            content: `reports/fraud`,
            requiredPermissions: [Permissions.FRAUD_REPORT],
            externalLink: false,
          },
        ],
        requiredPermissions: [],
        externalLink: false,
      },
      {
        label: "Lead",
        content: [
          {
            label: "Overall Lead Info",
            content: `reports/leads/overall-lead`,
            requiredPermissions: [Permissions.OVERALL_LEAD_REPORT],
            externalLink: false,
          },
          {
            label: "Store Category",
            content: `reports/leads/store-category`,
            requiredPermissions: [Permissions.STORE_CATEGORY_REPORT],
            externalLink: false,
          },
          {
            label: "Lead History",
            content: `reports/leads/lead-history`,
            requiredPermissions: [Permissions.LEAD_HISTORY_REPORT],
            externalLink: false,
          },
          {
            label: "Source Efficiency",
            content: `reports/leads/source-efficiency`,
            requiredPermissions: [Permissions.SOURCE_EFFICIENCY_REPORT],
            externalLink: false,
          },
          {
            label: "Store Efficiency",
            content: `reports/leads/store-efficiency`,
            requiredPermissions: [Permissions.STORE_EFFICIENCY_REPORT],
            externalLink: false,
          },
          {
            label: "Store Person Efficiency",
            content: `reports/leads/store-person-efficiency`,
            requiredPermissions: [Permissions.STORE_PERSON_EFFICIENCY_REPORT],
            externalLink: false,
          },
          {
            label: "Product Hierarchy Efficiency",
            content: `reports/leads/product-efficiency`,
            requiredPermissions: [Permissions.PRODUCT_HIERARCHY_EFFICIENCY_REPORT],
            externalLink: false,
          },
        ],
        requiredPermissions: [Permissions.LEAD_CSV_DOWNLOAD],
        externalLink: false,
      },
      // {
      //   label: "Campaign",
      //   content: `reports/campaign`,
      //   requiredPermissions: [Permissions.CAMPAIGN_TENANT_REPORT],
      //   externalLink: false,
      // },
      {
        label: "KPI",
        content: `reports/kpi`,
        requiredPermissions: [Permissions.KPI_REPORT],
        externalLink: false,
      },
      {
        label: "Offer",
        requiredPermissions: [Permissions.OFFER_TENANT_REPORT],
        content: `reports/offer`,
        externalLink: false,
      },
      {
        label: "Offer Redemption",
        requiredPermissions: [Permissions.OFFER_TENANT_REPORT],
        content: `reports/offer-redemption`,
        externalLink: false,
      },
      {
        label: "Store Health",
        requiredPermissions: [Permissions.STORE_HEALTH_REPORT],
        content: `reports/store-health`,
        externalLink: false,
      },
      // {
      //   label: "Store KPI",
      //   requiredPermissions: [Permissions.STORE_KPI_REPORT],
      //   content: `reports/store-kpi`,
      //   externalLink: false,
      // },
      {
        label: 'Store Status Report',
        content: `/reports/store-status`,
        requiredPermissions: [Permissions.STORE_STATUS_REPORT],
        externalLink: false,
      },
      {
        label: 'PWA Enrolment',
        content: `/reports/pwa-enrolment`,
        requiredPermissions: [Permissions.PERSONIFY],
        externalLink: false,
      },
      {
        label: 'Friend Referral Report',
        content: `/reports/friend-referral`,
        requiredPermissions: [Permissions.PERSONIFY],
        externalLink: false,
      },
    ],
    externalLink: false,
  },
  {
    label: "Import/Export",
    icon: ImportExport,
    requiredPermissions: [Permissions.DATA_MANAGE],
    content: [
      {
        label: "Bill Uploads",
        content: `bill-upload`,
        requiredPermissions: [Permissions.DATA_MANAGE],
        externalLink: false,
      },
      {
        label: "Upload Data",
        content: `/data-upload`,
        requiredPermissions: [Permissions.DATA_MANAGE],
        externalLink: false,
      },
      {
        label: "Large Data Uploads",
        content: `${dashboardClassic}/parallel-upload`,
        requiredPermissions: [Permissions.DATA_MANAGE],
        externalLink: true,
      },
      {
        label: "Download User Data",
        content: `${dashboardClassic}/user-data-download`,
        requiredPermissions: [Permissions.DATA_MANAGE],
        externalLink: true,
      },
    ],
    externalLink: false,
  },
  {
    label: "Social Media Campaign",
    icon: SocialMedia,
    content: [
      {
        label: "List Campaign",
        content: `/social-media-campaign`,
        requiredPermissions: [Permissions.SOCIAL_MEDIA],
        externalLink: false,
      }
    ],
    requiredPermissions : [],
    externalLink: false,
  },
  {
    label: "Settings",
    icon: Settings,
    requiredPermissions: [Permissions.DATA_MANAGE],
    content: `/settings`,
    externalLink: false,
  },
];

export default navConfig;
